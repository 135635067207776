import {
  REFRESH,
  SEND_ONLINE_FORM,
  SET_SERVER_STATUS,
  SET_LOADING,
  SET_LOADING2,
  SET_ERROR,
  SET_TOKEN,
  TO_MOBILE_CONSOLE,
} from '../types'; // eslint-disable-next-line*/
function MailReducer(state, action) {
  switch (action.type) {
    case SET_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case SET_LOADING2: {
      return {
        ...state,
        loading2: action.payload,
      };
    }
    case SET_ERROR: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case REFRESH: {
      return {
        ...state,
        isServerOnline: false,
        loading: false,
        loading2: false,
        msgFromBackend: null,
        error: null,
        success: null,
        csrfToken: null,
      };
    }
    case SEND_ONLINE_FORM: {
      return {
        ...state,
        success: action.payload,
        loading2: false,
        loading: false,
      };
    }
    case SET_SERVER_STATUS: {
      return {
        ...state,
        isServerOnline: action.payload.isServerOnline,
        msgFromBackend: action.payload.msgFromBackend,
        loading: false,
        loading2: false,
      };
    }
    case SET_TOKEN: {
      return {
        ...state,
        csrfToken: action.payload,
      };
    }
    case TO_MOBILE_CONSOLE: {
      return {
        ...state,
        mobileClg: JSON.stringify(action.payload),
      };
    }
    default:
      return state;
  }
}
export default MailReducer;
