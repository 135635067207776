// COMMON
export const SET_DARK_MODE = 'SET_DARK_MODE',
  REFRESH = 'REFRESH',
  SET_FORM_SAVE = 'SET_FORM_SAVE',
  SET_SETTINGS = 'SET_SETTINGS',
  SET_LOADING = 'SET_LOADING',
  SET_LOADING2 = 'SET_LOADING2',
  SET_ERROR = 'SET_ERROR',
  TO_MOBILE_CONSOLE = 'TO_MOBILE_CONSOLE';
// SENDING ONLINE FORM
export const SEND_ONLINE_FORM = 'SEND_ONLINE_FORM',
  SET_SERVER_STATUS = 'SET_SERVER_STATUS',
  SET_TOKEN = 'SET_TOKEN';
