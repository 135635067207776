import React, { useReducer } from 'react';
import SettingsContext from './settingsContext';
import settingsReducer from './settingsReducer';

import {
  isFunctionalCookieEnabled,
  isDarkModeCookieEnabled,
  isFormDataCookieEnabled,
} from '../../components/utils/checkCookies';

import { SET_DARK_MODE, REFRESH, SET_FORM_SAVE, SET_SETTINGS } from '../types'; // eslint-disable-next-line*/

// init state
const SettingsState = (props) => {
  let localSett = { darkMode: false, formSave: false }; // this is default setting
  if (isFunctionalCookieEnabled()) {
    // check if there are settings saved in a cookie, if not, use default above
    if (isDarkModeCookieEnabled()) {
      localSett = { ...localSett, darkMode: true };
    }
    if (isFormDataCookieEnabled()) {
      localSett = { ...localSett, formSave: true };
    }
  }
  const initialState = {
    darkMode: localSett.darkMode,
    formSave: localSett.formSave,
  };

  const [state, dispatch] = useReducer(settingsReducer, initialState);

  const setDarkMode = (booleanVal) => {
    dispatch({ type: SET_DARK_MODE, payload: booleanVal });
  };

  const setFormSave = (booleanVal) => {
    dispatch({ type: SET_FORM_SAVE, payload: booleanVal });
  };

  const setSettings = (obj) => {
    dispatch({ type: SET_SETTINGS, payload: obj });
  };

  const resetSettings = () => {
    dispatch({ type: REFRESH });
  };

  return (
    <SettingsContext.Provider
      value={{
        darkMode: state.darkMode,
        formSave: state.formSave,
        setDarkMode,
        setFormSave,
        setSettings,
        resetSettings,
      }}
    >
      {props.children}
    </SettingsContext.Provider>
  );
};

export default SettingsState;
