import './src/style.css';
import './src/styles/global.css';
import React from 'react';
import SettingsState from './src/context/settings/SettingsState';
import MailState from './src/context/mail/MailState';

export const onInitialClientRender = () => {
  setTimeout(function () {
    document.getElementById('___loader').style.display = 'none';
  }, 100);
};

export const wrapRootElement = ({ element }) => (
  <SettingsState>
    <MailState>{element}</MailState>
  </SettingsState>
); // without gatsby-ssr.js file - state wont work
// SO INCLUDE AND DONT FORGET ABOUT GATSBY-SSR!!
