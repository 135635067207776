import React, { useReducer, useState, useRef } from 'react';
import MailContext from './mailContext';
import mailReducer from './mailReducer';

import {
  REFRESH,
  SEND_ONLINE_FORM,
  SET_SERVER_STATUS,
  SET_LOADING,
  SET_LOADING2,
  SET_ERROR,
  SET_TOKEN,
  TO_MOBILE_CONSOLE,
} from '../types'; // eslint-disable-next-line*/

import { MAIL_API } from '../../components/links';
import axios from 'axios';

// init state
const displayConsoleLogInHtml = false;
axios.defaults.withCredentials = true;
const MailState = (props) => {
  const initialState = {
    isServerOnline: false,
    loading: false, // used for initial loading
    loading2: false,
    msgFromBackend: null,
    error: null,
    success: null,
    csrfToken: null,
    mobileClg: null, // used for log on mobile
  };
  const [refreshTokenAttempt, setRefreshTokenAttempt] = useState(0),
    stateRef = useRef(),
    msg1 = 'Interner Serverfehler. Bitte nutzen Sie einen anderen Kontaktweg.',
    msg2 = 'Der Server ist offline; das Online-Formular funktioniert nicht.';

  stateRef.current = refreshTokenAttempt;

  const [state, dispatch] = useReducer(mailReducer, initialState);

  const getStatus = async (dispatchToReducer = true) => {
    dispatch({
      type: SET_SERVER_STATUS,
      payload: {
        isServerOnline: true,
        msgFromBackend: null,
      },
    });
    try {
      const config = {
        withCredentials: true, // it will not save in document cookie, or storage. Look into network -> check_status -> cookies or headers or response
        /* headers: {
          'Content-Type': 'application/json',
        },*/ // DONT write application/json as you will get unexpected error. Delete it
      }; // token is written globally, so we dont add it here
      const res = await axios.post(
        `${MAIL_API}/creolic/check_status`,
        null,
        config
      );
      if (res?.status === 200 && res?.data) {
        let token = null;
        if (res.data.msg.csrf !== undefined) {
          token = res.data.msg.csrf;
        }
        if (dispatchToReducer) {
          dispatch({
            type: SET_TOKEN,
            payload: token,
          });
        } else return token;
      }
    } catch (error) {
      if (error?.response && error.response?.status) {
        // internal server error
        if (dispatchToReducer) {
          dispatch({
            type: SET_SERVER_STATUS,
            payload: {
              isServerOnline: false,
              msgFromBackend: msg1,
            },
          });
        } else return false;
      } else if (dispatchToReducer) {
        // server is really offline
        dispatch({
          type: SET_SERVER_STATUS,
          payload: {
            isServerOnline: false,
            msgFromBackend: msg2,
          },
        });
      } else {
        return false;
      }
    }
  };

  const checkIfServerOnline = async () => {
    dispatch({ type: SET_LOADING, payload: true });

    // dispatch({
    //   type: SET_SERVER_STATUS,
    //   payload: {
    //     isServerOnline: true,
    //     msgFromBackend: null,
    //   },
    // });
    try {
      const res = await axios.get(`${MAIL_API}/creolic/is_server_online`);
      if (res?.status === 200 && res?.data) {
        dispatch({
          type: SET_SERVER_STATUS,
          payload: {
            isServerOnline: true,
            msgFromBackend: null,
          },
        });
        return true;
      }
    } catch (error) {
      if (error?.response && error.response?.status) {
        // internal server error
        dispatch({
          type: SET_SERVER_STATUS,
          payload: {
            isServerOnline: false,
            msgFromBackend: msg1,
          },
        });
      } else {
        // server is really offline
        dispatch({
          type: SET_SERVER_STATUS,
          payload: {
            isServerOnline: false,
            msgFromBackend: msg2,
          },
        });
      }
      return false;
    }
  };

  const sendOnlineForm = async (obj) => {
    dispatch({ type: SET_LOADING2, payload: true });
    const config = {
      withCredentials: 'include',
    }; // token is written globally, so we dont add it here

    try {
      const res = await axios.post(`${MAIL_API}/nhfm/online_form`, obj, config);
      if (res.status === 200 && res.data) {
        // console.log('res: ', res);
        switch (res.data.code) {
          case 200: {
            dispatch({ type: SEND_ONLINE_FORM, payload: res.data });
            break;
          }
          case 403: {
            // token problem - maybe there was no token.
            // Or token was there, but cookie was old and form was next day posted.
            if (stateRef.current === 0) {
              console.log('token not valid, running again');
              // check server again, receive new token and post data
              setRefreshTokenAttempt(refreshTokenAttempt + 1);
              let newToken = await getStatus(false);
              obj.token = newToken;

              sendOnlineForm(obj);
            } else {
              dispatch({ type: SET_ERROR, payload: 'Token falsch.' });
            }
            break;
          }
          case 404: {
            dispatch({ type: SET_ERROR, payload: res.data });
            break;
          }
          default:
            break;
        }
      }
    } catch (error) {
      if (error?.response && error.response?.status) {
        // error with too many requests
        if (displayConsoleLogInHtml) {
          sendToMobileConsole(error);
        }
        dispatch({
          type: SET_SERVER_STATUS,
          payload: {
            isServerOnline: false,
            msgFromBackend:
              error.response.status === 429 ? error.response.statusText : msg1,
          },
        });
      } else {
        // server is really offline
        dispatch({
          type: SET_SERVER_STATUS,
          payload: {
            isServerOnline: false,
            msgFromBackend: msg2,
          },
        });
      }
    }
  };

  const sendToMobileConsole = (obj) => {
    // delete this function
    // this is only for testing
    dispatch({ type: TO_MOBILE_CONSOLE, payload: obj });
  };

  const sendMail = async (obj) => {
    // delete this function
    // this is only for testing
    // reset mobile console
    if (displayConsoleLogInHtml) {
      sendToMobileConsole({});
    }
    const config = {
      withCredentials: true,
    }; // token is written globally, so we dont add it here

    try {
      const res = await axios.post(`${MAIL_API}/creolic/test`, obj, config);
      if (res.status === 200 && res.data) {
        if (displayConsoleLogInHtml) {
          sendToMobileConsole({ 'res.status 200': res.data });
        }
        console.log('res.data', res.data);
      }
    } catch (error) {
      if (error?.response && error.response?.status) {
        console.log('error response', error.response);
        if (displayConsoleLogInHtml) {
          sendToMobileConsole({ 'error response': error.response });
        }
      } else {
        // server is really offline
        console.log('error while trying to send mail, error catch:', error);
        if (displayConsoleLogInHtml) {
          sendToMobileConsole({ 'error in try catch, error:': error });
        }
      }
    }
  };

  const resetMailValues = () => {
    setRefreshTokenAttempt(0);
    dispatch({ type: REFRESH });
  };

  return (
    <MailContext.Provider
      value={{
        isServerOnline: state.isServerOnline,
        loading: state.loading,
        loading2: state.loading2,
        msgFromBackend: state.msgFromBackend,
        error: state.error,
        success: state.success,
        csrfToken: state.csrfToken,
        mobileClg: state.mobileClg,
        checkIfServerOnline,
        sendOnlineForm,
        sendMail,
        getStatus,
        resetMailValues,
      }}
    >
      {props.children}
    </MailContext.Provider>
  );
};

export default MailState;
