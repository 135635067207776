export const FUNCTIONAL_CONSENT = 'creolic_functional_consent',
  BASIC_CONSENT = 'creolic_basic_consent',
  SESSION = 'creolic_session',
  TOKEN = 'csrfToken',
  FORM_SAVE = 'formSave',
  DARK_MODE = 'darkMode';

export const cookiesJson = {
  basic: [
    {
      id: 0,
      cookieName: BASIC_CONSENT,
      cookieDescription: `${BASIC_CONSENT} sets this cookie to remember user consent prefs so that their prefs are respected on subsequent visits to this site. It does not collect /store any personal information about the site visitors.`,
      cookieDuration: '1 year',
    },
    {
      id: 1,
      cookieName: SESSION,
      cookieDescription: `${SESSION} sets this cookie to save session ID when using online form. This is security prevention against bots. It does not collect /store any personal information about the site visitors.`,
      cookieDuration: '1 day',
    },
    {
      id: 2,
      cookieName: TOKEN,
      cookieDescription: `${TOKEN} sets this cookie to save CSRF token when using online form, bound with ${SESSION} cookie. This is security prevention against bots. It does not collect /store any personal information about the site visitors.`,
      cookieDuration: '1 day',
    },
  ],
  functional: [
    {
      id: 0,
      cookieName: FUNCTIONAL_CONSENT,
      cookieDescription: `${FUNCTIONAL_CONSENT} sets this cookie to remember user consent functional preferences so that their preferences are respected on subsequent visits to this site. It does not collect /store any personal information about the site visitors.`,
      cookieDuration: '1 year',
    },
    {
      id: 1,
      cookieName: 'creolic_settings',
      cookieDescription: 'Not in function',
      cookieDuration: '1 day',
    },
  ],
  settings: [
    { id: 0, settingName: FORM_SAVE },
    { id: 1, settingName: DARK_MODE },
  ],
};
